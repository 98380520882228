.InputContainer {
    display: flex;
    justify-content: space-between;
}

.FieldControl {
    width: 17vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
    margin-top: 3vw;
}
