.Card {
    width: 10vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.Card p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.Card span {
    font-weight: 700;
    font-size: 1vw;
    color: var(--color-primary);
}

.CardContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    gap: 1vw;
}
