.CreateButton {
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid var(--color-primary);
    border-radius: 0.5vw;
    padding: 0.45vw 1.19vw;
    cursor: pointer;
    width: max-content;
    font-size: 0.72vw;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    column-gap: 0.5vw;
}

.CreateButton img {
    height: 0.9vw;
    width: 0.9vw;
    right: 7rem;
}

.DownloadButton {
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid var(--color-primary);
    border-radius: 0.5vw;
    padding: 0 0.7vw;
    cursor: pointer;
    width: max-content;
    font-size: 0.72vw;
    font-weight: 500;
    letter-spacing: 0px;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    grid-column-gap: 0.5vw;
    margin-top: 1vw;
    height: 2vw;
}

.DownloadButton img {
    height: 1vw;
    width: 1vw;
}

.ButtonIcon {
    color: var(--color-primary);
    height: 1.1vw;
    width: auto;
}
