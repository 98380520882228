.GroupsContainer {
    margin-top: 2px;
    padding: 0vw 1vw;
    background: #f4f7fc;
    border-radius: 0.2vw;
    padding-top: 0.4vw;
}

.Label {
    padding-bottom: 1vw;
    align-items: center;
    display: flex;
    grid-column-gap: 1vw;
    cursor: pointer;
}

.DeviceList {
    padding-inline-start: 1.2vw;
    list-style-type: none;
    margin: 0.6vw 0;
}

.GroupsContainer > .Label:first-child {
    padding-bottom: 0.5vw;
}

.DeviceList li:last-child .Label {
    padding-bottom: 0.5vw;
}

.DeviceList .Label {
    grid-column-gap: 1vw;
}

.GroupsContainer div {
    font-weight: 500 !important;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    width: 31.25vw;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}
