.RecentVisitContainer {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: 1vw;
    bottom: 1vw;
    z-index: 12;
    max-width: 5vw;
}

.RecentVisitContainer:hover {
    cursor: pointer;
}

.RecentVisit {
    background-color: #240b3e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3vw;
    padding: 1.2vw;
    border-radius: 50%;
}

.RecentVisitExpand {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    background-color: #240b3e;
    border-radius: 1vw;
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
}

.RecentVisitExpand_Item {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0.3vw;
}

.RecentVisitExpand_Item_P {
    display: inline;
    margin: 0;
    font-size: 0.7vw;
    text-align: center;
}

.RecentVisitIcon {
    height: 2vw;
    width: 2vw;
}

.draggableDiv {
    width: 80px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
