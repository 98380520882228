.styleUl {
    list-style-type: none;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    font-size: 0.7vw;

    border-radius: 0.7vw;
}
.extraClass {
    background-color: chocolate;
    width: 20vw !important;
}
.Tooltip img {
    width: 1vw;
    height: 1vw;
}
