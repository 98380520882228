.FieldControl {
    width: 8.77vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.SubmitButton {
    width: 7.4vw;
    align-self: flex-end;
    margin-bottom: 0.1vw;
}

.Duration {
    width: 75.8vw;
    height: 2.34vw;
    background: #f4f7fc;
    margin: 1.56vw 0 0 0;
    display: flex;
    align-items: center;
}

.Events {
    background: white;
    padding: 0.5vw 1vw 1vw;
}

.DurationContent {
    margin: 0.625vw 1.82vw;
}

.DurationContent {
    text-align: center;
    font-weight: 600;
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.DatePickers {
    display: flex;
    align-items: flex-end;
    grid-column-gap: 0.51vw;
    justify-content: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    grid-row-gap: 1vw;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.SubmitButton {
    margin-bottom: 0.2vw;
}

.Action {
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}
