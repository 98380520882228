.Container {
    position: relative;
    display: flex;
    align-items: center;
}

.CalendarIcon {
    position: absolute;
    z-index: 2;
    left: 0.54vw;
    height: 1vw;
    width: 1vw;
    top: 0.45vw;
}
