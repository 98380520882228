.Details {
    width: 16vw;
    height: 34vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding: 1.56vw 0.9vw;
}

.Details > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Details > div:first-child img {
    cursor: pointer;
    height: 0.83vw;
    width: 0.83vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    width: 31.25vw;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.CollapsibleContainer {
    height: 32vw;
    overflow-y: auto;
}

.Label {
    align-items: center;
    display: flex;
    grid-column-gap: 1.2vw;
    cursor: pointer;
}

.GroupsContainer {
    margin-top: 2px;
    padding: 0.41vw 1vw;
    background: #f4f7fc;
    border-radius: 0.2vw;
}

.Typography div {
    font-weight: 500 !important;
}
.UpdatedDate {
    font-size: 0.8vw;
}

.GroupList {
    width: 16vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding: 1.56vw 0.9vw;
}

.GroupListSubContainer {
    overflow-x: hidden;
    height: 34vw;
    overflow-y: scroll;
}
