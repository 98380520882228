.AllDevices {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding: 1vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header > div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
}

.AddKey {
    width: 29.08vw;
    height: 11.22vw;
}

.ButtonContainer {
    margin-top: 1vw;
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.FieldControl {
    width: 9.077vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    margin-top: 1vw;
    align-items: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.RightContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 3vw;
}

.PowerManagement {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    height: 65vh;
    padding-top: 1vw;
    padding-right: 1vw;
    display: flex;
    flex-direction: column;
}

.OptionContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 1vw;
}

.Title {
    font-size: 1vw;
    font-weight: 600;
}

.Alert {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 1.5vw 1vw;
    border-radius: 0.78vw !important;
}
