.NoData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 1.5vw;
}

.NoData img {
    width: 16.3vw;
    height: 9.375vw;
    margin-top: 5vw;
}

.NoData div {
    margin-top: 1vw;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    font-weight: bold;
}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}
