.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.InputContainer {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 1vw;
}

.FieldControl3 {
    /* display: flex;
    justify-content: space-between;
    column-gap: 1vw; */
}

.FieldControl2 {
    width: 100%;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer input {
    margin-top: 0;
}

.Settings {
    max-height: 31vw;
    overflow-y: auto;
}

.MultiInputs {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.MultiInputs > div {
    flex: 0.5;
}

.BottomContainer {
    height: 28vw;
    width: 75vw;
    background-color: #ffffff;
    padding: 2vw 1vw;
    border-radius: 0.7vw;
}

.Actions {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
    row-gap: 2vw;
    justify-content: flex-end;
}

.TableFirstChild th:first-child {
    padding-left: 0;
}

.FilterWarapper {
    border-radius: 0.7vw;
    margin-top: 0;
    padding: 0.5vw 1vw 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AddInputActionWrapper {
    display: flex;
    justify-content: end;
    grid-column-gap: 0.2vw;
}
