.CapacityContent {
    width: 32vw;
}

.Content {
    margin: 0 1vw;
}

.CapacityContent table {
    border-collapse: collapse;
}

.CapacityContent tr,
.CapacityContent td,
.CapacityContent th {
    border-bottom: 1px solid rgb(129, 142, 148, 0.1);
    padding: 1vw;
}

.CapacityContent th {
    font-size: 0.72vw;
    font-weight: 500;
    color: #818e94;
}

.CapacityContent td {
    font-size: 0.72vw;
    color: #818e94;
    text-align: center;
}

.Title {
    color: #000000;
    font-weight: bold;
    font-size: 0.83vw;
}

.Container {
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
}

.ButtonContainer {
    width: 6vw;
    margin-left: auto;
}

.ButtonContainer2 {
    display: flex;
    justify-content: end;
}

.ButtonContainer2>div {
    width: auto;
}

.InfoModalContainer {
    height: auto;
    max-height: 75vh !important;
    width: auto;
    overflow: auto;
}

.InfoModalHeading {
    margin-left: 1.5vw;
    margin-top: 1.5vh;
}

.ContainerStart {
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
    align-items: start;
}