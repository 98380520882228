.Table {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding-bottom: 1vw;
    margin-top: 1vw;
    height: 100%;
    overflow-y: auto;
}

.Table table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.Table th:first-child {
    padding-left: 1vw;
}

.Table th {
    padding-top: 0.71vw;
    font-size: 0.729vw;
    color: var(--gray-700);
    padding-bottom: 1vw;
    text-transform: uppercase;
    font-weight: 500;
}

.Table tr {
    cursor: pointer;
    height: 2.34vw;
    color: #818e94;
    font-size: 0.729vw;
}

.Table tr:hover:not(:first-child) {
    background-color: #f4f7fc;
    color: #000000;
    font-weight: 600;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
}

.ActionContainer {
    display: flex;
    justify-content: center;
    grid-column-gap: 0.5vw;
}

.ActionContainer img {
    cursor: pointer;
    height: 0.83vw;
    width: 0.83vw;
}

.NoData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 1.5vw;
}

.NoData img {
    width: 16.3vw;
    height: 9.375vw;
    margin-top: 5vw;
}

.NoData div {
    margin-top: 1vw;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    font-weight: bold;
}

/* new style */

.Content {
    border: 0.052vw solid var(--gray-200);
    border-radius: 0.833vw;
    overflow: hidden;
}

.Header {
    background: var(--gray-50);
    cursor: pointer;
}

.Table tr:not(:last-child) {
    border-bottom: 0.052vw solid var(--gray-100);
}

.Table td:not(:last-child) {
    border-right: 0.052vw solid var(--gray-100);
}
