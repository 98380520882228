.Bids {
    background: #fff;
    padding: 0.3vw 1.5vw;
    border-radius: 0.78vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
    text-align: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header > div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    margin-top: 1vw;
    align-items: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 2vw;
}

.ActionContainer img {
    width: 1.2vw;
    height: 1vw;
}

.ActionContainer {
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}

.Action {
    display: flex;
    grid-column-gap: 0.5vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.Badges {
    display: flex;
    align-items: center;
    margin-bottom: 0.51vw;
    grid-column-gap: 1vw;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.FilterContainer {
}

.licenseContainer {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 0.78vw !important;
}
