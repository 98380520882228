.FieldControl {
    width: 8.77vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.Title {
}

.FormContainer {
    margin-top: 1vw;
}

.Subtitle {
    font-size: 0.72vw;
    font-weight: 500;
    margin: 1.5vw 0;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 1vw;
    margin-top: 1vw;
}

.ButtonContainer button {
    width: 5vw;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}


.FieldControl2 {
    width: 100%;
}
