.Year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-primary);
}

.Month {
    display: flex !important;
    flex-wrap: wrap !important;
    position: relative;
    top: 0.156vw;
    cursor: pointer;
    text-align: center;
}

.Selected {
    background: var(--color-primary);
    border-radius: 0.417vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    color: white !important;
}
