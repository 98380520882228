.Card {
    width: 30%;
    height: 10.6vw;
    border-radius: 0.78vw;
    background: white;
    padding: 1.56vw 0.83vw;
    cursor: pointer;
}

.Title {
    font-size: 0.83vw;
    letter-spacing: 0px;
    color: var(--color-primary);
    text-transform: capitalize;
    opacity: 1;
    font-weight: bold;
}

.SubTitle {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 500;
}

.Content ul {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    line-height: 2;
    padding-inline-start: 0;
    margin-left: 1.6vw;
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 2vw;
    list-style-position: inside;
}

.CardsContainer {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.SubTitleWrapper {
    display: flex;
    gap: 1vw;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.VersionDetailsWrapper {
    min-width: 20vw;
}
