.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.InputContainer {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 1vw;
}

.InputContainer label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer input {
    margin-top: 0;
}

.Settings {
    max-height: 31vw;
    overflow-y: auto;
}

.MultiInputs {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.MultiInputs > div {
    flex: 0.5;
}

.BottomContainer {
    height: 28vw;
    width: 75vw;
    background-color: #ffffff;
    padding: 2vw 1vw;
    border-radius: 0.7vw;
}

.Actions {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
    row-gap: 2vw;
    justify-content: flex-end;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
}

.LogContainer {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}

.HeadingContainer {
    display: flex;
    cursor: pointer;
    grid-column-gap: 1vw;
    align-items: center;
}

.ModalContainer {
    width: 70vw;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.TableHeadingPaddingBottom {
    padding-bottom: 0.5vw;
}

.ButtonRight {
    width: 10.39vw;
    margin-top: 1vw;
    margin-left: auto;
}

.AmountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FieldControl2 {
    width: 9.077vw;
}

.FieldControl2 input {
    margin-top: 0;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.DropDnContainer {
    width: 10vw;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0.5vw 1vw 1vw 1vw;
    border-radius: 0.78vw !important;
}

.FilterRightSection {
    display: flex;
    cursor: pointer;
    align-items: center;
    grid-column-gap: 1vw;
}

.ActionIcons {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
}

.StopIcon {
    color: red;
}

.StartIcon {
    color: green;
}

.DatePickers {
    display: flex;
    align-items: flex-end;
    grid-column-gap: 0.51vw;
    justify-content: flex-end;
}
