.ModalSettings {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    align-items: center;
    column-gap: 1vw;
}

.ModalSettings img {
    height: 1vw;
    width: 1vw;
}
