.DeleteModal {
    display: flex;
    flex-direction: column;
    row-gap: 2vw;
    width: 25vw;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.Heading {
    font-size: 1vw;
    font-weight: bold;
    line-height: normal;
}
.yes {
    width: 4vw;
}
.ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2vw;
}
.note {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 18vw;
    height: 2vw;
    background-color: rgba(210, 105, 30, 0.308);
    border-radius: 0.5vw;
    padding: 1vw;
    font-size: 0.8vw;
    text-align: center;
}
