.CreateAlert {
    width: 24.2vw;
    /* height: 34.5vw; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    opacity: 1;
    margin-top: 1vw;
    padding: 1.1vw;
}

.InputContainer {
    margin-top: 1vw;
    grid-row-gap: 1vw;
    display: flex;
    flex-direction: column;
}

.ButtonContainer {
    width: 10.39vw;
    margin-top: 3vw;
    margin-left: auto;
}

.BottomContainer {
    display: flex;
    /* flex-direction: column; */
    height: 30vw;
}

.TabsContainer {
    width: -webkit-fill-available;
    margin-top: 1vw;
    margin-left: 2vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.73vw;
    padding: 0.7vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl {
    width: 25.25vw;
    margin-top: 0.5vw;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 10.39vw;
}

.ButtonContainer button {
    width: 100%;
}

.CreateAlert ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}
.CreateAlert ::-moz-placeholder {
    /* Firefox 19+ */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}
.CreateAlert :-ms-input-placeholder {
    /* IE 10+ */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}
.CreateAlert :-moz-placeholder {
    /* Firefox 18- */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}

.MainChartContainer {
    display: grid;
    grid-template-columns: 100%;
    gap: 2vw;
}
.ChartContainer {
    width: 100%;
    height: 25vw;
    margin-top: 2vw;
}
.DropdownContainer {
    width: 10vw;
    margin-bottom: 0.5vw;
    margin-top: -3vw;
}
