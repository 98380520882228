.Card {
    width: 30%;
    height: 10.6vw;
    border-radius: 0.78vw;
    background: white;
    padding: 1.56vw 0.83vw;
}
.Card2 {
    width: 30%;
    height: 10.6vw;
    border-radius: 0.78vw;
    background: white;
    padding: 1.56vw 0.83vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-left: -1vw;
}
.Card2 img {
    width: 5vw;
    height: 5vw;
}
.TitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0vw;
}
.TitleWrapper img {
    cursor: pointer;
}
.Title {
    font-size: 0.83vw;
    letter-spacing: 0px;
    color: var(--color-primary);
    text-transform: capitalize;
    opacity: 1;
    font-weight: bold;
}

.SubTitle {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 500;
}

.Content ul {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    line-height: 2;
    padding-inline-start: 0;
    margin-left: 1.6vw;
}

.CardsContainer {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    justify-content: flex-start;
    align-items: center;
}
