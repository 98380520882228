.CreateAlert {
    width: 24.2vw;
    /* height: 34.5vw; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    opacity: 1;
    margin-top: 1vw;
    padding: 1.1vw;
}

.InputContainer {
    grid-row-gap: 1vw;
    display: flex;
    flex-direction: column;
}

.ButtonContainer {
    width: 10.39vw;
    margin-top: 3vw;
    margin-left: auto;
}

.ButtonContainer2 {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.BottomContainer {
    display: flex;
    height: 30vw;
}

.BottomContainer2 {
    height: 28vw;
}

.TabsContainer {
    width: -webkit-fill-available;
    margin-top: 1vw;
    margin-left: 2vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.73vw;
    padding: 0.7vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl {
    margin-top: 0.5vw;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 10.39vw;
}

.ButtonContainer button {
    width: 100%;
}

.CreateAlert ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}

.CreateAlert ::-moz-placeholder {
    /* Firefox 19+ */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}

.CreateAlert :-ms-input-placeholder {
    /* IE 10+ */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}

.CreateAlert :-moz-placeholder {
    /* Firefox 18- */
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 0.625vw;
}

.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FilterWarapper {
    border-radius: 0.7vw;
    margin-top: 0;
    padding: 0.5vw 1vw 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TableFirstChild th:first-child {
    padding-left: 0;
}

.Title {
    font-size: 0.8333vw;
    font-weight: bold;
    color: #000000;
    padding-left: 1vw;
    padding-top: 0.61vw;
}

.lastReported {
    padding-left: 1vw;
    padding-top: 0.1vw;
}

.Head {
    display: flex;
    justify-content: space-between;
    padding: 1vw;
    align-items: center;
}

.addInputActionWrapper {
    display: flex;
    justify-content: end;
}

.addInputActionbutton {
    height: 1.8rem;
    margin-left: 0.3rem;
}

.FormContentNoSpace {
    margin-bottom: 0.4rem;
}

.FilterContainer {
    background: white;
    padding: 1.04vw;
    margin-bottom: 1vw;
    border-radius: 0.78vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FlexCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ConsumptionInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2vw;
}
