.ScheduleTestInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.ControlTestInputContainer {
    display: flex;
    gap: 1vw;
    align-items: end;
}

.FieldControl3 {
    width: 15vw;
}

.FieldControl3 input {
    margin-top: 0;
}

.FieldControl3 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FilterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5vw;
    margin-top: 2vw;
}

.AutoRefreshContainer {
    display: flex;
    gap: 1vw;
    align-items: end;
    justify-content: start;
}

.AutoRefreshLabel {
    font-size: 0.72vw;
    font-weight: 500;
    margin-left: -4px;
}
