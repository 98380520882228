.ChartContainer {
    display: flex;
    grid-column-gap: 0.91vw;
    width: 100%;
}

.TopGraph {
    width: 29.7vw;
    height: 20.2vw;
    position: relative;
}

.CoolingGraph {
    width: 37.7vw;
    height: 20.2vw;
}

.ChartWithDateSelector {
    margin-bottom: 1vw;
}

.CreateAlert {
    width: 24.2vw;
    /* height: 34.5vw; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    opacity: 1;
    margin-top: 1vw;
    padding: 1.1vw;
}

.InputContainer {
    grid-row-gap: 1vw;
    display: flex;
    flex-direction: column;
}

.ButtonContainer {
    width: 7.39vw;
}

.BottomContainer {
    display: flex;
}

.TabsContainer {
    width: -webkit-fill-available;
    margin-top: 1vw;
    margin-left: 2vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.73vw;
    padding: 0.7vw;
}

.FilterDropdown {
    position: absolute;
    right: 1vw;
    top: 0.7vw;
}

.Home {
    display: grid;
    grid-row-gap: 2vw;
}

.Revenue {
    text-align: center;
    background: white;
    border-radius: 0.78125vw;
    padding: 2vw 0vw;
    width: 16vw;
    height: 16vw;
}

.Revenue .Title {
    font-size: 0.83vw;
    font-weight: bold;
}

.Revenue .Subtitle {
    font-size: 1.7vw;
    font-weight: 600;
    text-align: center;
}

.LinkTitle {
    color: #818e94;
    font-size: 0.729vw;
    padding-left: 1.5vw;
    padding-top: 0.61vw;
    text-decoration: underline;
    cursor: pointer;
}
.EventContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    opacity: 1;
    padding: 1.5vw;
}

.ChartGroupName {
    font-size: 0.8333vw;
    font-weight: bold;
    color: #000000;
    padding-right: 1.5vw;
    padding-top: 0.61vw;
}

.TableFirstChild th:first-child {
    padding-left: 0;
}
