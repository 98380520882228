.Container {
    font-size: 0.83vw;
    text-transform: capitalize;
    width: 28.5vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    height: 35vw;
}

.Container img {
    width: 20vw;
}

.Title {
    color: #000000;
    font-weight: bold;
}

.Subtitle {
    color: var(--color-primary);
}

.Email {
    font-weight: 600;
    text-decoration: underline;
    color: var(--color-primary);
    text-transform: lowercase;
}

.Button {
    width: 7vw;
    display: flex;
    justify-content: center;
}
