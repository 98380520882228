::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #31313b;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

:root {
    --color-primary: #0f123f;
    --color-secondary: #f7f2ff;
    --gray-50: #f4f7fa;
    --gray-100: #eff2f5;
    --gray-200: #dee4eb;
    --gray-300: #c3ccd6;
    --gray-400: #a8b5c2;
    --gray-500: #8593a3;
    --gray-600: #6a7682;
    --gray-700: #505862;
    --gray-800: #353b41;
    --gray-900: #1b1d21;
}

::-moz-selection {
    background: var(--color-primary);
    color: #ffffff;
}

::selection {
    background: var(--color-primary);
    color: #ffffff;
}

.link {
    text-decoration: underline;
}

.flex {
    display: flex;
}

.black {
    color: black;
}

.main-content {
    min-height: 100vh;
    background-color: #f4f7fc;
    display: flex;
}

.btn-primary {
    border: transparent;
    outline: none;
    min-width: 7vw;
    cursor: pointer;
    background: var(--color-primary) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #2f427e1a;
    border-radius: 0.5vw;
    height: 2vw;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.729vw;
    color: #ffffff;
    margin-top: 1vw;
    width: 100%;
}

.btn-secondary {
    min-width: 7vw;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #818e94;
    border-radius: 0.5vw;
    height: 2vw;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.729vw;
    color: #818e94;
    margin-top: 1vw;
    width: 100%;
}

.btn-delete {
    background: brown;
    color: white;
    min-width: 7vw;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #818e94;
    border-radius: 0.5vw;
    height: 2vw;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.729vw;
    color: #ffffff;
    margin-top: 1vw;
    width: 100%;
}

.btn-green {
    background: darkgreen;
    color: white;
}

.Collapsible__contentInner {
    width: 16vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.side-bar .Collapsible__contentInner {
    width: 16vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.error-msg {
    font-size: 0.71vw;
    color: red;
    margin-top: 0.3vw;
}

.required {
    color: red;
}

.filter-dropdown {
    display: flex;
    align-items: center;
    grid-column-gap: 0.51vw;
    font-size: 0.91vw;
    font-weight: 500;
    margin-bottom: 1vw;
}

.number-dropdown .Dropdown-control {
    height: 2vw !important;
}

.filter-dropdown .Dropdown-root {
    width: auto;
}

.filter-button {
    width: 4vw;
}

.frequency-filters .btn-primary {
    width: 4vw;
    height: 2vw;
}

.modal-date-picker .react-datepicker-popper {
    height: 100% !important;
    width: 100% !important;
}

/*  */
.modal-date-picker .react-datepicker-wrapper input {
    font-size: 0.95vw;
    height: 2.3vw !important;
    outline: none;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a !important;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw !important;
    padding-left: 0.64vw !important;
    width: 8vw !important;
}

.react-tabs {
    height: 100%;
}

/*-----------DROPDOWN-----------*/
.Dropdown-control {
    align-items: center !important;
    display: flex !important;
    font-size: 0.95vw !important;
    width: -webkit-fill-available !important;
    height: 2.6vw !important;
    outline: none !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw !important;
    padding-left: 1.4vw;
}

.DateLabels .Dropdown-control {
    height: 2.1vw !important;
}

.Dropdown-option {
    padding: 3px 10px !important;
}

.Dropdown-menu {
    font-size: 0.95vw !important;
    width: -webkit-fill-available !important;
    border-radius: 0.5vw !important;
}

.Dropdown-arrow {
    border-color: black transparent transparent !important;
    right: 1vw !important;
    top: 0.9vw !important;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent black !important;
}

.DropdownContainer .Dropdown-control {
    margin-top: unset !important;
    width: 7vw !important;
    padding-left: 2vw !important;
}

.DropdownContainer .Dropdown-menu {
    width: 7.5vw !important;
}

.optionContainer li {
    color: rgba(51, 51, 51, 0.8) !important;
    background-color: #fff !important;
    height: max-content !important;
    display: flex !important;
    align-items: center !important;
    padding: 5px 5px !important;
}

.optionContainer li input {
    height: max-content !important;
}

.optionContainer li:hover {
    background-color: #f2f9fc !important;
    color: #333 !important;
}

/*---------------------------DROPDOWN--------------------___*/

.notFound {
    font-size: 0.95vw !important;
}

.chip {
    background: var(--color-primary) !important;
    padding: 0px 5px !important;
    font-size: 0.92vw !important;
}

.icon_cancel:before {
    margin-right: unset !important;
}

#multiselectContainerReact > div:first-child {
    padding: 0 !important;
    border: transparent !important;
    position: relative !important;
}

#multiselectContainerReact > div:first-child::before {
    position: absolute;
    border-color: black transparent transparent !important;
    border-style: solid;
    border-width: 5px 5px 0;
    height: 0;
    width: 0;
    right: 1vw !important;
    top: 1.2vw !important;
    content: ' ';
    display: block;
}

.react-tabs__tab {
    height: 1.7vw !important;
    min-width: 7.31vw !important;
    box-shadow: 0px 2px 10px #2f427e1a !important;
    border: 1px solid #818e94 !important;
    border-radius: 0.5vw !important;
    font-size: 1vw !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #818e94 !important;
}

.react-tabs__tab--selected {
    height: 1.7vw !important;
    min-width: 7.31vw !important;
    background: var(--color-primary) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a !important;
    border-radius: 0.5vw !important;
    color: white !important;
    font-size: 1vw !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: white;
}

.react-tabs__tab-list {
    border-bottom: none !important;
    display: flex !important;
    grid-column-gap: 1vw;
}

.ventilation > div:first-child > ul:first-child {
    padding: 1vw 1vw !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 0.78vw !important;
}

.img-container {
    display: flex;
}

/*-----------RADIO-----------*/
.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
}

.react-toggle-track {
    background-color: #cfd4d6 !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none !important;
    background-color: #cfd4d6 !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #cfd4d6 !important;
}

.react-toggle--checked:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128d15 !important;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128d15 !important;
}

.react-toggle--checked .react-toggle-thumb {
    left: 18px !important;
    border-color: #19ab27 !important;
}

.react-toggle-thumb {
    border: none !important;
    width: 15px !important;
    height: 16px !important;
}

.react-toggle-track {
    width: 35px !important;
    height: 18px !important;
}

/*-----------------__MODAL___--------------*/

.ReactModal__Content {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 0.78vw !important;
    border: none !important;
    z-index: 12 !important;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 11 !important;
}

.QR.ReactModal__Content--after-open {
    padding: 0vw !important;
}

/*-------------REACT DATE PICKER----------*/

.react-datepicker {
    font-family: 'poppins' !important;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none !important;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    padding: 1.56vw 1.91vw;
}

.react-datepicker__current-month {
    padding-bottom: 1.3vw !important;
    text-align: center !important;
    font-size: 0.833vw !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    font-weight: 500 !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: unset !important;
    border-bottom: none !important;
    border-top-left-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__day-names {
    background: var(--color-primary) 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    color: white !important;
    outline: none !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: white !important;
    outline: none !important;
}

.react-datepicker__navigation--next {
    top: 2.3vw !important;
    right: 2.8vw !important;
    outline: none !important;
}

.react-datepicker__navigation--previous {
    left: 3vw !important;
    top: 2.2vw !important;
    outline: none !important;
}

.react-datepicker__week .react-datepicker__day-name,
.react-datepicker__week .react-datepicker__day,
.react-datepicker__week .react-datepicker__time-name {
    color: black !important;
    outline: none !important;
}

.react-datepicker__week .react-datepicker__day--keyboard-selected,
.react-datepicker__week .react-datepicker__month-text--keyboard-selected,
.react-datepicker__week .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__week .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: var(--color-primary) !important;
    color: #fff !important;
    outline: none !important;
}

.react-datepicker-wrapper input {
    width: 9vw !important;
    border: none !important;
    background: #f4f7fa !important;
    border-radius: 0.5rem !important;
    height: 2.03vw !important;
    outline: none;
    padding: 0vw 0 0vw 2vw !important;
    font-size: 1rem !important;
    color: var(--gray-900);
    font-weight: 600;
}

.react-datepicker-popper {
    display: flex !important;
    position: absolute !important;
    height: 100vh !important;
    width: 100vw !important;
    left: 0 !important;
    top: 0 !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: rgb(0, 0, 0, 0.9) !important;
    z-index: 30 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    transform: unset !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__month .react-datepicker__month--selected {
    background-color: var(--color-primary) !important;
    color: white !important;
}

/*--------------TOAST-------------------*/

.Toastify__toast {
    border-radius: 15px !important;
    min-height: unset !important;
    width: 18.59vw !important;
    padding: 1vw 1.5vw !important;
}

.Toastify__toast--success {
    background: #14ae39 0% 0% no-repeat padding-box !important;
}

.Toastify__toast-body {
    font-size: 0.83vw !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
}

.Toastify__close-button {
    height: 1.92vw !important;
    width: 2.1vw !important;
    background: #ffffff !important;
    outline: none !important;
    border: none !important;
    padding: 0 !important;
    cursor: pointer !important;
    transition: 0.3s ease !important;
    align-self: flex-start !important;
    border-radius: 0.5vw !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    opacity: 1 !important;
}

.Toastify__close-button--success {
    color: #14ae39 !important;
}

.Toastify__close-button--error {
    color: #e74c3c !important;
}

.Toastify__toast-container {
    width: unset;
}

.black {
    color: black;
}

button.btn-primary {
    min-width: 7vw;
}

.days-input input {
    width: unset !important;
}

.goto-dropdown {
    position: absolute;
    right: 1vw;
    top: 1.6vw;
    display: flex;
    align-items: center;
    grid-column-gap: 0.51vw;
    font-size: 0.91vw;
    font-weight: 500;
    cursor: pointer;
}

.goto-dropdown > div {
    text-decoration: underline;
}

@media only screen and (min-width: 1600px) {
    .apexcharts-toolbar svg {
        height: 1vw !important;
        width: 1vw !important;
        font-size: 2vw !important;
    }

    .apexcharts-toolbar > div {
        height: 1vw !important;
        width: 1vw !important;
        transform: scale(1) !important;
    }
}

/* Tool tip csss */

/* React tool tip light  */
.react-tooltip-lite {
    background: var(--color-primary);
    color: white;
    border-radius: 0.7vw;
    max-width: 35vw !important;
    font-size: 0.7vw !important;
    min-width: auto;
}

.react-tooltip-lite-arrow {
    border-color: var(--color-primary);
    position: relative;
    /* background: #31313b; */
}

.inputStyle input {
    /* margin-top: 1vw; */
    font-size: 0.95vw;
    max-width: 4vw;
    min-height: 1.9vw;
    outline: none;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw;
    padding-left: 1.4vw;
    width: -webkit-fill-available;
}

.inputStyle2 input {
    font-size: 0.95vw;
    height: 2.3vw;
    outline: none;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw;
    padding-left: 1.4vw;
    width: -webkit-fill-available;
}

/* PAGINATION */

.react-pagination {
    display: flex;
    list-style-type: none;
    grid-column-gap: 0.1vw;
    width: 100%;
    margin: 2vw 0;
    justify-content: flex-end;
    padding: 0;
}

.react-pagination a,
.react-pagination a:hover,
.react-pagination a:focus {
    font-size: 0.721vw;
    color: #a3a3a3;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.react-pagination li {
    padding: 0.41vw 0.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-pagination .selected {
    background: #31313b 0% 0% no-repeat padding-box;
    border-radius: 0.41vw;
    padding: 0.41vw 0.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-pagination .selected a {
    color: white;
}

.previous a,
.previous a:focus,
.previous a:hover,
.next a,
.next a:focus,
.next a:hover {
    color: #31313b;
}

.previous.disabled a,
.next.disabled a {
    color: #4f4f4f;
}

/* inProgress css */
.inProgress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1vw;
    font-size: 1vw;
    font-weight: bold;
}

.inProgress img {
    width: 30vw;
    height: 30vw;
}

/* searchable dropdown */
.searchable__controls input {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 1em;
    padding: 14px;
    width: 100%;
    color: #4f4f4f !important;
    position: relative;
}

.css-g1d714-ValueContainer {
    max-height: 2.3vw !important;
}

.css-yk16xz-control {
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    opacity: 0.5 !important;
}

/* date selector css */
input[type='date'i] {
    align-items: center !important;
    display: flex !important;
    font-size: 0.95vw !important;
    width: -webkit-fill-available !important;
    height: 2.5vw !important;
    outline: none !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw !important;
    padding-left: 0.3vw;
}

input[type='month'i] {
    align-items: center !important;
    display: flex !important;
    font-size: 0.95vw !important;
    width: -webkit-fill-available !important;
    height: 2.5vw !important;
    outline: none !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 10px #2f427e1a;
    border: 1px solid #ebebeb !important;
    border-radius: 0.5vw !important;
    padding-left: 0.3vw;
    /* margin-left: 2vw; */
}

/* control buttons */
.Controls {
    display: flex;
    grid-column-gap: 1vw;
    justify-content: center;
}

.Controls > div {
    width: 7.3vw;
    height: 2.08vw;
    box-shadow: 0px 2px 10px #2f427e1a;
    border-radius: 0.55vw;
    color: white;
    font-size: 0.73vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Green {
    background: #14af38 0% 0% no-repeat padding-box;
}

.Red {
    background: #ff4646 0% 0% no-repeat padding-box;
}

.Blue {
    background: dodgerblue;
}

.InputIcon {
    height: 1vw;
    color: #6a7682;
}

/* react time picker */

.react-time-picker {
    height: 100%;
}

.react-time-picker__wrapper {
    display: flex;
    padding: 10px;
    align-items: center;
    border: none !important;
}

.react-time-picker--open {
    border: none !important;
}

.react-time-picker__inputGroup__input,
.react-time-picker__inputGroup__leadingZero {
    color: var(--gray-900);
    font-weight: 600 !important;
    font-size: 1rem !important;
    border: none !important;
}

.react-time-picker__inputGroup input[type='number'] {
    border: none !important;
}

.rdrDayToday .rdrDayNumber span:after {
    content: none !important;
}

.rdrNextPrevButton {
    background-color: var(--color-primary);
}

.rdrPprevButton i {
    border-color: transparent white transparent transparent;
}

.rdrNextButton i {
    border-color: transparent transparent transparent white;
}

.rdrMonthAndYearPickers select {
    display: flex;
    text-align: center;
    width: 120px;
}

.rdrMonthAndYearPickers select:hover {
    background-color: transparent !important;
}

.rdrMonthPicker,
.rdrYearPicker {
    margin: 0;
}

/* date range picker */
.rdrDefinedRangesWrapper {
    display: none;
}

.rdrDayNumber::after {
    border: 2px solid var(--color-primary) !important;
}
