.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ToggleContainer > div {
    margin-top: 0;
}

.ActionContainer {
    display: flex;
    justify-content: center;
    grid-column-gap: 1vw;
}

.ActionContainer img {
    height: 1.2vw;
    width: 1.2vw;
}

.ActionContainer > div:first-child {
    display: flex;
    align-items: center;
}

.InputContainer input {
    margin-top: 0;
}
