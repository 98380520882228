.ChartContainer {
    background-color: white;
    border-radius: 0.78125vw;
    width: 100%;
    position: unset !important;
    height: 100%;
}

.Title {
    font-size: 0.8333vw;
    font-weight: bold;
    color: #000000;
    padding-left: 1.5vw;
    padding-top: 0.61vw;
}

.lastReported {
    padding-left: 1.5vw;
    padding-top: 0.1vw;
    position: relative;
}

.LinkTitle {
    font-size: 0.8333vw;
    font-weight: bold;
    color: #000000;
    padding-left: 1.5vw;
    padding-top: 0.61vw;
    text-decoration: underline;
    cursor: pointer;
}

.Head {
    display: flex;
    justify-content: space-between;
    padding-top: 0.8vw;
}
