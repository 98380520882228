.Alert {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 1.5vw 1vw;
    border-radius: 0.78vw !important;
}

.CreateButton {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 1vw;
    align-items: center;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FieldControl input {
    margin-top: 0;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcons {
    display: flex;
    grid-column-gap: 0.5vw;
    align-items: center;
    justify-content: center;
}

.ActionIcons img {
    height: 1vw;
}

.ButtonsContainer {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 1vw;
    align-items: center;
    margin-left: 2vw;
}

.ContainerBorderRadius {
    border-radius: 0.5vw;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.FieldControlGrow {
    flex-grow: 1;
}

.FieldControl2 {
    width: 8.77vw;
}

.FieldControl2 input {
    margin-top: 0;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InActive {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.Active {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.ModalWidth {
    min-width: 20vw;
}

.TableHeadSection {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 1vw;
    align-items: center;
}
